import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    methodActive: JSON.parse(localStorage.getItem('methodActive')) || false,
};

const methodActiveSlice = createSlice({
    name: 'methodActive',
    initialState,
    reducers: {
        setMethodActive: (state, action) => {
            state.methodActive = action.payload;
            localStorage.setItem('methodActive', JSON.stringify(action.payload));
        }
    }
});

export const { setMethodActive } = methodActiveSlice.actions;
export default methodActiveSlice.reducer;
