import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {clearCart, setDisabled, removeCartCount, setGT, cashPaymentPopup} from '../../redux/atcSlice'; 
import './success.scss';

const Success = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(''); 
  const [redirect, setRedirect] = useState(false);
  
  // Extract query parameters
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get('order_id');
  const textStatus = searchParams.get('status');

  // Extract only the numeric part of the order_id
  const orderNumber = orderId ? orderId.split('_')[0] : null;

  const juspay_order = orderId ?? null;

  // Function to send data to the API
  const sendOrderStatus = async (orderNumber, textStatus) => {
    const data = JSON.stringify({
      query: `mutation updateOrderStatus($input: UpdateStatusInput!) {
        UpdateStatus(input: $input) {
          message
          status
        }
      }`,
      variables: {
        input: {
          order_id: orderNumber,
          juspay_order:juspay_order,
          status: textStatus
        }
      }
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://mcstaging.candere.com/graphql',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      const responseData = response.data;
      // Check the status field in the response to determine success or failure
      if (responseData?.data?.UpdateStatus?.status === 'success') {
        setResult(textStatus);
        localStorage.removeItem('offline');
        dispatch(cashPaymentPopup(false));
        dispatch(setDisabled(true)); 
        dispatch(removeCartCount());  
        localStorage.removeItem("storeBillingAddress");
        localStorage.removeItem("grandTotal");
        localStorage.removeItem("isSelectedAddress");
        localStorage.removeItem("methodActive");
      } else {
        dispatch(cashPaymentPopup(false));
        setResult(textStatus);
      }
    } catch (error) {
      console.error('Error:', error);
      dispatch(cashPaymentPopup(false));
      setResult(textStatus);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);// Turn off loading once the request is done
    }
  };

  // Call the function when the component mounts
  let offLine = localStorage.getItem('offline');
  useEffect(() => {    
    dispatch(clearCart());
    if (orderNumber && textStatus) {
      sendOrderStatus(orderNumber, textStatus);
    } else if(offLine){
      navigate('/success');
      localStorage.removeItem('offline');
    }else {
      // If no valid orderNumber or status, redirect to homepage
      setRedirect(true);
    }
  }, [orderNumber, textStatus]);

  // Handle redirection based on redirect state
  useEffect(() => {
    if (redirect) {
      navigate('/');
    }
  }, [redirect, navigate]);

  useEffect(() => {
    localStorage.removeItem('offline');
    setResult('');
  }, []);

  // Display loading message
  if (loading) {
    return (
      <div className='sePage'>
        <div className='sePage-box'>Loading Status.........</div>        
      </div>
    )
  }

  return (
    <div className='sePage'>
      <div className='sePage-box'>
        {result ? (
          <h1 className='sePage-result'>Order {result === "CHARGED" ? "Success" : result}</h1>
        ) : (
          <h1 className='sePage-result'>Loading...</h1>
        )}
        <p className='sePage-orderNumber'>Order Number: {orderNumber}</p>
        {textStatus ? (
          <p className="sePage-status">Status: {textStatus==="CHARGED" ? "Success" : result}</p>
        ) : (
          <p className="sePage-loading">Loading...</p>
        )}
        <Link to="/" title='Navigate to Homepage' className='sePage-redirect'>Navigate to Homepage</Link>
      </div>
    </div>
  );
};

export default Success;
