import React, {useState} from 'react';
import Categories from '../../components/categories/Categories';
import Searchbox from '../../components/searchbox/Searchbox';

const CustomerDetails = () => {

  return (
    <>
        <Searchbox/>
        <Categories/>
    </>
  )
}

export default CustomerDetails

