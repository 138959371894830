import axios from 'axios';

// Function to get customer account data using async/await
export const customerAccountData = async (userToken, customerId) => {
  const query = `
    {
      customer {
        addresses {
          id
          firstname
          lastname
          default_billing
          default_shipping
          street
          city
          region {
            region_code
            region
          }
          postcode
          country_code
          telephone
        }
      }
    }
  `;

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://mcstaging.candere.com/graphql',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`, // Include token if required
    },
    data: JSON.stringify({ query, variables: {} }),
  };

  try {
    const response = await axios.request(config);
    return response.data; // Return the response data on success
  } catch (error) {
    console.error("Error fetching customer account data:", error);
    throw error; // Rethrow error to handle it in the calling code
  }
};
