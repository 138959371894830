import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import { sheetOpen, sheetClose } from "../../redux/bottomSheetSlice";
import { startSession, updateSessionDuration, endSession } from "../../redux/sessionSlice";
import SectionTitle from "../sectionTitle/SectionTitle";
import close from "../../assets/images/close.svg";
import sessionAlert from "../../assets/images/sessionAlert.svg";
import "./Session.scss";
import { toast } from "react-toastify";

const Session = () => {
  const dispatch = useDispatch();
  const isSheetOpen = useSelector((state) => state.bottomSheet.isSheetOpen);
  const isSheetId = useSelector((state) => state.bottomSheet.isSheetId);
  const { sessionActive } = useSelector((state) => state.userSessionData);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
   const [errors, setErrors] = useState({ email: '', mobile: '', userName:''});


  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidMobileNumber = (number) => /^\d{10}$/.test(number);

    //form Validation when user input the field
    const validateForm = () => {
        let userNameError = '';
        let emailError = '';
        let mobileError = '';

        // User validation
        if (!userName) {
            userNameError = 'Customer name is requires';
        }

        // Email validation
        if (!emailRegex.test(email)) {
            emailError = 'Invalid email';
        }

        // Mobile number validation
        if (!isValidMobileNumber(phone.replace(/^\+91/, ""))) {
            mobileError = 'Mobile number must be 10 digits';
        }

        setErrors({ email: emailError, mobile: mobileError, userName: userNameError});

        return !emailError && !mobileError && !userNameError;
    };

  useEffect(() => {
    let interval;
    
    if (sessionActive) {
      interval = setInterval(() => {
      // dispatch(updateSessionDuration());
        const sessionData = JSON.parse(localStorage.getItem("localStorageSessionData")) || {};
  
        if (sessionData.sessionStartTime) {
          const updatedDuration = Math.floor((Date.now() - sessionData.sessionStartTime) / 1000);
          
          // Update Local Storage
          sessionData.sessionDuration = updatedDuration;
          localStorage.setItem("localStorageSessionData", JSON.stringify(sessionData));
        }
      }, 1000); // Runs every second
    }
  
    return () => clearInterval(interval);
  }, [sessionActive]); // Only depends on sessionActive
  

  const handleOpenModal = () => {
    dispatch(sheetClose());
    setIsModalOpen(true);
  };

  const handleCloseModal = (e) => {
    e.stopPropagation();
    dispatch(sheetOpen("sessionInfo"));
    setIsModalOpen(false);
  };

  const handleCloseModalComplete = () => {
    setIsModalOpen(false);
    dispatch(sheetClose());
  };

  const handleStartSession = () => {
    if (!validateForm()) {
      return;
    }else{
      dispatch(startSession({ userName: userName.trim(), email: email.trim(), phone: phone.trim() }));
      dispatch(sheetClose());
      setUserName("");
      setEmail("");
      setPhone("");
      toast.success("Session has been closed!", {autoClose: 1000,});
    }
  };

  const handleEndSession = () => {
    dispatch(endSession());
    toast.error("Session has been closed!", {autoClose: 1000,});
  };

  return (
    <div className="session">
      <p
        className="session--action"
        onClick={() => (sessionActive ? handleEndSession() : dispatch(sheetOpen("sessionInfo")))}
      >
        {sessionActive ? "End Session" : "Start Session"}
      </p>

      <BottomSheet
        open={isSheetId === "sessionInfo" && isSheetOpen}
        onDismiss={() => dispatch(sheetClose())}
        defaultSnap={({ snapPoints, lastSnap }) => lastSnap ?? Math.min(...snapPoints)}
        header={
          <div className="sheetHeader">
            <SectionTitle title="Customer Info" />
            <div className="sheetHeader--close" onClick={handleOpenModal}>
              <img src={close} alt="BottomSheet Close" className="img-fluid" />
            </div>
          </div>
        }
      >
        <div className="sheetBody sessionForm">
          <form className="sheetBody--form" onSubmit={(e) => e.preventDefault()}>
            <div className="input_group_container">
              <div className="input_group_">
                <input
                  className="input_type_text"
                  type="text"
                  placeholder="Full Name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <div className="label_ pushedUp">Full Name</div>
                {errors.userName && <div className="error">{errors.userName}</div>}
              </div>
            </div>
            <div className="input_group_container">
              <div className="input_group_">
                <input
                  className="input_type_text"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="label_ pushedUp">Email</div>
                {errors.email && <div className="error">{errors.email}</div>}
              </div>
            </div>
            <div className="input_group_container">
              <div className="input_group_">
                <input
                  className="input_type_text"
                  type="text"
                  placeholder="Mobile Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <div className="label_ pushedUp">Mobile Number</div>
                {errors.mobile && <div className="error">{errors.mobile}</div>}
              </div>
            </div>
            <div className="base_btn btn_lg primary_btn" onClick={handleStartSession}>
              Submit
            </div>
          </form>
        </div>
      </BottomSheet>

      {isModalOpen && (
        <div className="session--modal-overlay">
          <div className="session--modal-content">
            <img src={sessionAlert} alt="Alert" />
            <p className="session--modal-content-title">Confirm Exit</p>
            <p className="session--modal-content-text">
              Are you sure you want to exit the form?
            </p>
            <div className="session--modal-buttons">
              <button className="base_btn btn_sm primary_btn" onClick={handleCloseModalComplete}>
                Yes
              </button>
              <button className="base_btn btn_sm secondary_btn" onClick={handleCloseModal}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Session;
