import { createSlice } from "@reduxjs/toolkit";

const getStoredSession = () => {
  const storedSession = JSON.parse(localStorage.getItem("localStorageSessionData"));
  return storedSession || {
    sessionActive: false,
    sessionStartTime: null,
    sessionEndTime: null,
    sessionDuration: 0,
    sessionName:"",
    sessionEmail:"",
    sessionPhone:"",
    isCustomer: false,
  };
};

const initialState = getStoredSession();

const sessionSlice = createSlice({
  name: "userSessionData",
  initialState,
  reducers: {
    startSession: (state, action) => {
        const { userName, email, phone } = action.payload;
        state.sessionActive = true;
        state.sessionStartTime = Date.now();
        state.sessionEndTime = null;
        state.sessionDuration = 0;
        state.isCustomer= false;
        state.sessionName=userName;
        state.sessionEmail=email;
        state.sessionPhone= phone.replace(/^\+91/, "");
        localStorage.setItem('localStorageSessionData', JSON.stringify({
              sessionActive: true,
              sessionName:userName,
              sessionEmail:email,
              sessionPhone: phone.replace(/^\+91/, ""),
              sessionStartTime: Date.now(),
              sessionEndTime:null,
              sessionDuration: 0,
              isCustomer: false,
            })
        );
    },
    updateSessionDuration: (state) => {
      if (state.sessionActive && state.sessionStartTime) {
        state.sessionDuration = Math.floor(
          (Date.now() - state.sessionStartTime) / 1000
        );

        const userSessionData = JSON.parse(localStorage.getItem("localStorageSessionData"));
        if (userSessionData) {
          userSessionData.sessionDuration = state.sessionDuration;
          localStorage.setItem("localStorageSessionData", JSON.stringify(userSessionData));
        }
      }
    },
    endSession: (state) => {
      state.sessionActive = false;
      state.sessionStartTime = null;
      state.sessionEndTime = Date.now();

      // Store session history instead of removing it
      const userSessionData = JSON.parse(localStorage.getItem("localStorageSessionData"));
      if (userSessionData) {
        userSessionData.sessionActive = false;
        userSessionData.sessionEndTime = state.sessionEndTime;
        localStorage.setItem("localStorageSessionData", JSON.stringify(userSessionData));
      }
    },
  },
});

export const { startSession, updateSessionDuration, endSession } =
  sessionSlice.actions;

export default sessionSlice.reducer;
