import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Card from '../card/Card';
import Pageinfo from '../pageinfo/Pageinfo';
import SectionTitle from '../sectionTitle/SectionTitle';
import { useSelector, useDispatch } from 'react-redux';
import { setProducts, setLoading, setTotalProducts, setProductType, clearProducts } from '../../redux/productSlice';
import ScrollToTop from '../scrollTop/ScrollToTop';
import { fetchProductsData } from '../../services/fetchProductData';
import './list.scss';
import Searchbox from '../searchbox/Searchbox';

const List = () => {
  const params = useParams();
  const selectedCategory = params.label;
  const dispatch = useDispatch();
  const productsList = useSelector((state) => state.products.items);
  const { totalProducts, productType } = useSelector((state) => state.products);
  const isLoading = useSelector((state) => state.products.loading);
  const [page, setPage] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState(null);
  const observerRef = useRef(null);
  const { storeCode, agentCodeOrPhone, storeName } = useSelector((state) => state.agent);
  const { customer_id, token } = useSelector((state) => state.user);
  const selectedStore = useSelector((state) => state.nearbyStore.selectedStore);
  const [categoryTitle, setCategoryTitle] = useState(selectedCategory);
  const [categoryValue, setCategoryValue] = useState('');
  const nearbyStores = useSelector((state) => state.nearbyStore.nearbyStores);


   const storeCodesList = nearbyStores?.map(store => store.store_code);
   const selectedStoreCode = [storeCode, ...storeCodesList].filter(Boolean).join(",");
   

   //for Store Label On product
   const storeNamesLabel = {};
     if (storeCode) {
       storeNamesLabel[storeCode] = storeName ;
     }
     nearbyStores?.forEach(store => {
       if (store.store_code && store.place_name) {
         storeNamesLabel[store.store_code] = store.place_name;
       }
     });


  const fetchProducts = async (currentPage) => {   
    if (lastPageNumber && currentPage > lastPageNumber) return;
    dispatch(setLoading(true));
    try {
      const productResponseData = await fetchProductsData(
        selectedStoreCode ? selectedStoreCode : storeCode,
        agentCodeOrPhone,
        customer_id,
        categoryValue,
        currentPage,
        token
      );

      if (productResponseData.data.status === 'success') {
        const { products, product_types, productCount, lastPageNumber } = productResponseData.data;
        dispatch(setProducts(products));
        dispatch(setProductType(product_types));
        dispatch(setTotalProducts(productCount));
        setLastPageNumber(lastPageNumber);
      }
    } catch (error) {
      console.error('Error Fetching data:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && (!lastPageNumber || page < lastPageNumber)) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const categoryMap = {
      all: '',
      rings: '583',
      earrings: '584',
      necklace: '682',
      bangles: '586',
      bracelet: '591',
      solitaire: '1579',
      mangalsutra: '1057',
      otherJewellery: '1890',
      gift: '985',
    };

    const newCategoryValue = categoryMap[selectedCategory] || '';
    setCategoryValue(newCategoryValue);
    setPage(1);
    dispatch(clearProducts());
  }, [selectedCategory, dispatch]);

  
  useEffect(() => {
    if (categoryValue !== '') {      
      fetchProducts(page);
    } else if (selectedCategory === 'all') {     
      fetchProducts(page);
    }
  }, [categoryValue, page]);

  console.log("categoryValue");
  console.log(categoryValue);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    });

    if (observerRef.current) observerRef.current.disconnect();

    const productToObserveIndex = (page - 1) * 18 + 10; // 15th product of the current page
    const productToObserve = document.querySelector(`.productCategories--item-${productToObserveIndex}`);

    if (productToObserve) {
      observer.observe(productToObserve);
    }

    observerRef.current = observer;
    return () => observer.disconnect();
  }, [productsList, page]);

  return (
    <>
    <Searchbox/>
      <div className="plpWrapper">
        <SectionTitle title="Product Catalogue (Instore)" />
        <div className="productCategories">
          <p className="productCategories-title">Product Categories</p>
          <div className="productCategories--options">
            <ul className="productCategories--lists">
              <NavLink to="/categories/all" className="productCategories--item" >All Jewellery</NavLink>
              <NavLink to="/categories/rings" className="productCategories--item">Rings</NavLink>
              <NavLink to="/categories/earrings" className="productCategories--item">Earrings</NavLink>
              <NavLink to="/categories/necklace" className="productCategories--item">Necklace</NavLink>
              <NavLink to="/categories/bangles" className="productCategories--item">Bangles</NavLink>
              <NavLink to="/categories/bracelet" className="productCategories--item">Bracelet</NavLink>
              <NavLink to="/categories/solitaire" className="productCategories--item">Solitaire</NavLink>
              <NavLink to="/categories/mangalsutra" className="productCategories--item">Mangalsutra</NavLink>
            </ul>
          </div>
        </div>
        <Pageinfo
          title={selectedCategory==="all"? "All Jewellery" : selectedCategory}
          count={
            totalProducts > 1
              ? `${totalProducts} Items`
              : `${totalProducts} Item`
          }
        />
        <div className="cardWrapper">
          {productsList?.length > 0 &&
            productsList.map((item, index) => {
              const storeName = storeNamesLabel?.[item.offline_store_identifier] || "";
              return (
                <Card
                  key={`ps-${index + 1}`}
                  item={item}
                  labelName={storeName}
                  className={`productCategories--item-${index + 1}`}
                />
              )
            })}
          <ScrollToTop />
        </div>
        {isLoading && (
          <div className="cardWrapper placeholderLoading" style={{ marginTop: '20px' }}>
              <div className="ph-item alignItemCenter">
                  <div className="ph-col-12">
                      <div className="ph-picture mb-15"></div>
                      <div className="ph-row">                            
                          <div className="ph-col-4"></div>
                          <div className="ph-col-8 empty"></div>
                          <div className="ph-col-6"></div>
                          <div className="ph-col-6 empty"></div>
                          <div className="ph-col-12"></div>
                      </div>
                  </div>
              </div>
              <div className="ph-item alignItemCenter">
                  <div className="ph-col-12">
                      <div className="ph-picture mb-15"></div>
                      <div className="ph-row">                           
                          <div className="ph-col-4"></div>
                          <div className="ph-col-8 empty"></div>
                          <div className="ph-col-6"></div>
                          <div className="ph-col-6 empty"></div>
                          <div className="ph-col-12"></div>
                      </div>
                  </div>
              </div>
              <div className="ph-item alignItemCenter">
                  <div className="ph-col-12">
                      <div className="ph-picture mb-15"></div>
                      <div className="ph-row">                            
                          <div className="ph-col-4"></div>
                          <div className="ph-col-8 empty"></div>
                          <div className="ph-col-6"></div>
                          <div className="ph-col-6 empty"></div>
                          <div className="ph-col-12"></div>
                      </div>
                  </div>
              </div>
              <div className="ph-item alignItemCenter">
                  <div className="ph-col-12">
                      <div className="ph-picture mb-15"></div>
                      <div className="ph-row">                            
                          <div className="ph-col-4"></div>
                          <div className="ph-col-8 empty"></div>
                          <div className="ph-col-6"></div>
                          <div className="ph-col-6 empty"></div>
                          <div className="ph-col-12"></div>
                      </div>
                  </div>
              </div>
              <div className="ph-item alignItemCenter">
                  <div className="ph-col-12">
                      <div className="ph-picture mb-15"></div>
                      <div className="ph-row">                            
                          <div className="ph-col-4"></div>
                          <div className="ph-col-8 empty"></div>
                          <div className="ph-col-6"></div>
                          <div className="ph-col-6 empty"></div>
                          <div className="ph-col-12"></div>
                      </div>
                  </div>
              </div>
              <div className="ph-item alignItemCenter">
                  <div className="ph-col-12">
                      <div className="ph-picture mb-15"></div>
                      <div className="ph-row">                            
                          <div className="ph-col-4"></div>
                          <div className="ph-col-8 empty"></div>
                          <div className="ph-col-6"></div>
                          <div className="ph-col-6 empty"></div>
                          <div className="ph-col-12"></div>
                      </div>
                  </div>
              </div>
          </div>
        )}
        {!isLoading && lastPageNumber && page >= lastPageNumber && (
          <div className="endOfDataMessage" style={{ textAlign: 'center', marginTop: '20px' }}>
            <p>No more data to load</p>
          </div>
        )}
      </div>
    </>
  );
};

export default List;