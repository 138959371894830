import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import 'react-datepicker/dist/react-datepicker.css';
import "./Reports.scss";

const GSTSalesRegister = () => {
  // API Configuration
  const API_URL = "https://dev-erpnext.candere360.com/api/method/getreport";
  const API_HEADERS = {
    'Content-Type': 'application/json',
    'Authorization': 'token 671ee6fc8b2a7c9:0272eee472c7f01'
  };

  // State for the component
  const [reportName, setReportName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({
    limit: 1000,
    offset: 0,
    totalRecords: 0,
    currentRecords: 0
  });
  const [currentPage, setCurrentPage] = useState(1);

  // Report options
  const reportOptions = [
    "All Stores",
    "Prev Day Stock Report",
    "Employee Details",
    "Item Master Candere",
    "GST Sales Register",
    "GST Sales Return",
    "Instrumetn Wise Sales Return",
    "Instrument wise sales",
    "Bank Book Report",
    "Cash Book Report",
    "Invoice Wise Collection Summary",
    "Inventory Valuation",
    "B2B Sales PnL",
    "Candere Outward Register",
    "Net Sales Report CDR",
    "MTD Pnl",
    "Console P&L",
    "Head off Sales PnL",
    "Location Wise LOB Wise Sales Report",
    "Stock Inward Register",
    "Stock Outward Register",
    "Sales Register Report",
    "Sales Return Register",
    ];

   // Reset all display states before fetching new data
   const resetDisplayStates = () => {
    setData([]);
    setError(null);
    setSelectedRows([]);
    setPaginationInfo({
      limit: 1000,
      offset: 0,
      totalRecords: 0,
      currentRecords: 0
    });
    setCurrentPage(1);
  };

  // Normalize API response data
  const normalizeData = (responseData) => {
    try {
      if (!responseData) return [];
      
      if (responseData.message && Array.isArray(responseData.message)) {
        setPaginationInfo({
          limit: responseData.limit_info?.limit || 1000,
          offset: responseData.limit_info?.offset || 0,
          totalRecords: responseData.limit_info?.total_records || 0,
          currentRecords: responseData.limit_info?.current_records || 0
        });
        return responseData.message;
      }
      
      if (Array.isArray(responseData)) return responseData;
      if (typeof responseData === 'object') return [responseData];
      return [];
    } catch (err) {
      console.error('Error normalizing data:', err);
      return [];
    }
  };

  // Fetch data from API
  const fetchData = async (page = 1) => {
    if (!reportName) {
      setError('Please select a report from the dropdown');
      return;
    }

    setLoading(true);
    resetDisplayStates(); // Reset all display states before fetching new data

    try {
      const filters = {};
      if (fromDate && toDate) {
        filters.from_date = fromDate.toISOString().split('T')[0];
        filters.to_date = toDate.toISOString().split('T')[0];
      }

      const offset = (page - 1) * 1000; // Calculate offset based on page number

      const requestBody = {
        report_name: reportName,
        report_type: 'csv',
        limit: 1000,
        offset: offset,
        filters: Object.keys(filters).length > 0 ? filters : undefined
      };

      const response = await axios.post(API_URL, requestBody, {
        headers: API_HEADERS,
        timeout: 30000
      });

      console.log(response);
      

      const normalizedData = normalizeData(response.data);
      setData(normalizedData);
      setCurrentPage(page);
      
      if (normalizedData.length === 0) {
        toast.warning('No data available for the selected criteria');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || err.message || 'Failed to fetch data';
      setError(errorMessage);
      toast.error(`Error: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  // Handle page change
  const handlePageChange = (page) => {
    fetchData(page);
  };

  // Convert data to CSV format
  const convertToCSV = (dataToExport) => {
    if (!dataToExport || dataToExport.length === 0) return '';
    
    const headers = Object.keys(dataToExport[0]);
    const csvRows = [];
    csvRows.push(headers.join(','));
    
    dataToExport.forEach(row => {
      const values = headers.map(header => {
        const value = row[header];
        const escaped = ('' + (value !== null && value !== undefined ? value : ''))
          .replace(/"/g, '""');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    });
    
    return csvRows.join('\n');
  };

  // Download data as CSV file
  const exportToCSV = (dataToExport, fileName) => {
    try {
      const csvContent = convertToCSV(dataToExport);
      if (!csvContent) {
        toast.warning('No data to export');
        return;
      }
      
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, `${fileName}_${new Date().toISOString().split('T')[0]}.csv`);
      
      toast.success('Download started');
    } catch (error) {
      console.error('Error exporting to CSV:', error);
      toast.error('Failed to generate CSV file');
    }
  };

  // Download single row as CSV
  const downloadSingleRow = useCallback((row) => {
    exportToCSV([row], `${reportName}_SingleRecord`);
  }, [reportName]);

  // Download all data as CSV - Note: This will only download current page data
  const downloadAllData = useCallback(() => {
    if (data.length === 0) {
      toast.warning('No data to download');
      return;
    }
    exportToCSV(data, `${reportName}_Page_${currentPage}`);
  }, [data, reportName, currentPage]);

  // Download selected rows as CSV
  const downloadSelected = useCallback(() => {
    if (selectedRows.length === 0) {
      toast.warning('No rows selected');
      return;
    }
    exportToCSV(selectedRows, `${reportName}_SelectedRecords`);
  }, [selectedRows, reportName]);

  // Handle row selection
  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  // Clear selections
  const clearSelections = () => {
    setToggleCleared(!toggleCleared);
    setSelectedRows([]);
  };

  // Generate columns dynamically based on data
  const columns = useMemo(() => {
    if (data.length === 0) return [];
    
    return Object.keys(data[0]).map(key => ({
      name: key,
      selector: row => row[key] !== null && row[key] !== undefined ? row[key].toString() : 'N/A',
      sortable: true,
      wrap: true,
      minWidth: '150px'
    }));
  }, [data]);

  // Custom actions for the table
  const actions = useMemo(() => (
    <div className="report--action">
      <button
        onClick={downloadAllData}
        className="report--action-btn"
        title="Download current page data"
      >
        Download Current Page
      </button>
      {selectedRows.length > 0 && (
        <button
          onClick={downloadSelected}
          className="report--action-btn"
          title={`Download ${selectedRows.length} selected records`}
        >
          Download Selected ({selectedRows.length})
        </button>
      )}
    </div>
  ), [downloadAllData, downloadSelected, selectedRows.length]);

  // Add actions column if we have data
  const finalColumns = useMemo(() => {
    if (columns.length === 0) return columns;
    return [
      ...columns,
      {
        name: 'Actions',
        selector: 'actions',
        cell: (row) => (
          <button
            onClick={() => downloadSingleRow(row)}
            className="report-singleDownload"
            title="Download this record"
          >
            Download
          </button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: '120px'
      }
    ];
  }, [columns, downloadSingleRow]);

  // Fetch data when dependencies change
  useEffect(() => {
    if (reportName) {
      fetchData(1); // Always start at page 1 when report changes
    }
  }, [reportName, fromDate, toDate]);

  return (
    <div className="report-main">
        <div className="report-head">
            <div className='report--input'>
                <div className="report--input-wrapper">
                    <label className="report--input-label">Select Report</label>
                    <select
                    value={reportName}
                    onChange={(e) => setReportName(e.target.value)}
                    className="report--input-select"
                    >
                    <option value="">-- Select a report --</option>
                    {reportOptions.map((option) => (
                        <option key={option} value={option}>
                        {option}
                        </option>
                    ))}
                    </select>
                </div>

                <div className="report--input-wrapper">
                    <label className="report--input-label">Date Range</label>
                    <DatePicker
                    selectsRange
                    startDate={fromDate}
                    endDate={toDate}
                    onChange={([start, end]) => {
                        setFromDate(start);
                        setToDate(end);
                    }}
                    isClearable={true}
                    placeholderText="Select date range to filter"
                    className="report--input-select"
                    dateFormat="yyyy-MM-dd"
                    />
                </div>
            </div>
        
            <div className='report--options'>
                <button
                onClick={() => fetchData(1)}
                disabled={loading || !reportName}
                className="report--action-btn refreshBtn"
                >
                {loading ? 'Loading...' : 'Refresh Data'}
                </button>

                <button
                onClick={() => {
                    setReportName('');
                    setFromDate(null);
                    setToDate(null);
                    setData([]);
                    setError(null);
                    clearSelections();
                    setCurrentPage(1);
                    toast.info('Filters cleared');
                }}
                className="report--action-btn clearBtn"
                >
                Clear All
                </button>
            </div>
        </div>

        <div className="report-body">
            {!reportName && !loading && (
                <div className="report--info">Please select a report from the dropdown to view data</div>
            )}

            {loading && (
                <div className="report--info">Loading data...</div>
            )}

            {data.length > 0 && (
                <div className="report--table">
                <DataTable
                    title={`${reportName} ${fromDate && toDate ? `(${fromDate.toISOString().split('T')[0]} to ${toDate.toISOString().split('T')[0]})` : ''}`}
                    columns={finalColumns}
                    data={data}
                    selectableRows
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    noContextMenu
                    pagination
                    paginationServer
                    paginationTotalRows={paginationInfo.totalRecords}
                    paginationDefaultPage={currentPage}
                    onChangePage={handlePageChange}
                    paginationPerPage={1000}
                    paginationRowsPerPageOptions={[1000]}
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    responsive
                    highlightOnHover
                    striped
                    actions={actions}
                    customStyles={{
                        headCells: {
                            style: {
                                backgroundColor: '#ebf8fb',
                                fontWeight: 'bold',
                                fontSize: '14px',
                            },
                        },
                        cells: {
                            style: {
                                fontSize: '13px',
                            }
                        }
                    }}
                    persistTableHead
                />
                <div className="report--pagination-info">
                    Showing records {((currentPage - 1) * 1000) + 1} to {Math.min(currentPage * 1000, paginationInfo.totalRecords)} of {paginationInfo.totalRecords}
                </div>
                </div>
            )}

            {reportName && !loading && data.length === 0 && (
                <div className="report--info">
                No data found for the selected {fromDate && toDate ? 'date range' : 'report'}.
                </div>
            )}
        </div>
    </div>
  );
};

export default GSTSalesRegister;