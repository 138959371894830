import React from 'react'
import GSTSalesRegister from '../../components/reports/GSTSalesRegister';

const Report = () => {
  return (
    <div className='report--wrapper'>
      <GSTSalesRegister />
    </div>
  )
}

export default Report
